<template>
  <div class="w-full">
    <Jumbotron3 />
    <div v-if="step === 0" class="place-create-0 flex flex-col items-center">
      <h1>
        놀고 있는 당신의공간<br />
        경쟁임차<span>로 임대해주세요!</span>
      </h1>
      <div>경쟁임차란?</div>
      <p>
        비공개 임차 가격경쟁을 통해서 <b>가장 합리적인 가격</b> 낙찰<br />
        1:1 채팅으로 임대 문의도 바로, 가격 조율도 바로 !
      </p>
      <button @click="onStart">유휴공간 등록하기</button>
    </div>

    <div
      v-if="step !== 0"
      class="flex-1 w-full flex justify-center items-center"
    >
      <div class="place-create-wrapper">
        <h2>노는공간 <span>등록하기</span></h2>
        <hr />

        <div class="step-1" v-if="step === 1">
          <p class="description">어떤 공간을 만드시나요?</p>
          <div class="category">
            <p class="label">분류 <span>*</span></p>
            <Radio
              v-for="item in classifications"
              v-model="categoryId"
              :value="item.id"
              :label="item.title"
              :key="item.id"
            />
          </div>
        </div>

        <div class="step-2" v-else-if="step === 2">
          <p class="description">어디에 어떤 공간인가요?</p>
          <AddressInput :onChange="onAddressChange" />
        </div>

        <div class="step-3" v-else-if="step === 3">
          <p class="description">공간 크기를 입력해주세요.</p>
          <div class="label">공급면적기준<span>*</span></div>
          <Input
            v-model="size"
            :type="'number'"
            :max="1000"
            :min="1"
            :placeholder="'면적단위(평)'"
          />
          <p class="hint">
            ※ 최소 3㎡(1평) 부터 최대 3300㎡(1000평) 까지만 입력이 가능합니다.
          </p>
        </div>

        <div class="step-4" v-else-if="step === 4">
          <p class="description">거래방식을 입력해주세요.</p>
          <div class="time">
            <p class="label">임대기간<span>*</span></p>
            <v-select
              v-model="leaseTime"
              :from="leaseTimeOptions"
              :filterable="false"
              placeholder="임대기간 선택"
              as="title::id"
              :multiple="false"
            />
          </div>

          <div class="label">임대료 (월단위)<span>*</span></div>
          <Input
            v-model="leasePrice"
            :type="'number'"
            :placeholder="'만원'"
            :min="1"
            :max="1000000000"
          />

          <div class="label">보증금<span>*</span></div>
          <Input
            v-model="utilities"
            :type="'number'"
            :placeholder="'만원'"
            :min="1"
            :max="1000000000"
          />

          <div class="label">조율 가능여부</div>
          <Switches v-model="isChangeable" theme="bulma" color="blue" />
        </div>

        <div class="step-6" v-if="step === 5">
          <p class="description">
            추가정보를 입력해 주세요
          </p>
          <FileInput
            :label="'공간 내부사진'"
            :multiple="true"
            :onChange="onInnerFilesChange"
          />
          <FileInput
            :label="'공간 외부사진'"
            :multiple="true"
            :onChange="onOuterFilesChange"
          />
          <FileInput
            :label="'공간 기타사진'"
            :multiple="true"
            :onChange="onOtherFilesChange"
          />
        </div>

        <div class="step-7 flex flex-col items-center" v-if="step === 6">
          <img src="@/assets/complete.png" />
          <p>
            공간등록이 완료되었습니다.<br />
            관리자 승인 후 경쟁임차가 시작됩니다.
          </p>
        </div>

        <div class="button-group w-full flex flex-col">
          <button
            @click="onNext"
            v-if="step !== 6"
            class="next w-full flex justify-center items-center"
            :disabled="isLoading"
          >
            <span v-if="step !== 5">다음</span>
            <span v-else-if="!isLoading && step === 5">제출</span>
            <PulseLoader :loading="isLoading" :size="'8px'" :color="'white'" />
          </button>
          <button
            @click="onHome"
            v-if="step == 6"
            class="next flex justify-center items-center"
          >
            홈으로
          </button>
          <button
            @click="onBack"
            v-if="step !== 1 && step !== 6"
            class="back w-full flex justify-center items-center"
          >
            뒤로
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Jumbotron3 from "@/components/Jumbotron3.vue";
import AddressInput from "@/components/AddressInput.vue";
import Input from "@/components/Input.vue";
import Radio from "@/components/Radio.vue";
import FileInput from "@/components/FileInput.vue";
import { mapState } from "vuex";
import services from "@/services";
import Switches from "vue-switches";

export default {
  name: "PlaceWrite",
  components: {
    PulseLoader,
    Jumbotron3,
    AddressInput,
    Input,
    Radio,
    FileInput,
    Switches,
  },
  data() {
    return {
      step: 0,
      isLoading: false,
      address: "",
      addressDetail: "",
      oldAddress: "",
      post: null,
      latitude: null,
      longitude: null,
      categoryId: null,
      size: null,
      utilities: null,
      leasePrice: null,
      leaseTime: null,
      isChangeable: false,
      placeInnerFiles: [],
      placeOuterFiles: [],
      placeOtherFiles: [],
      leaseTimeOptions: [
        { id: 1, title: "1년" },
        { id: 2, title: "2년" },
        { id: 3, title: "3년" },
        { id: 4, title: "4년" },
        { id: 5, title: "5년" },
      ],
    };
  },
  methods: {
    onStart() {
      if (this.isLoggedIn) {
        this.step = 1;
      } else {
        this.$toast.open({ message: "로그인을 해주세요", type: "error" });
      }
    },
    async onNext() {
      if (this.step === 1) {
        if (!this.categoryId) {
          this.showError("분류를 선택해주세요");
          return;
        }

        this.step = 2;
      } else if (this.step === 2) {
        if (!this.address) {
          this.showError("주소를 입력해주세요");
          return;
        } else if (!this.addressDetail) {
          this.showError("상세주소를 입력해주세요");
          return;
        }

        this.step = 3;
      } else if (this.step === 3) {
        if (!this.size) {
          this.showError("공급면적을 입력해주세요");
          return;
        }

        this.step = 4;
      } else if (this.step === 4) {
        if (!this.leasePrice) {
          this.showError("월임대료를 입력해주세요");
          return;
        }
        if (!this.utilities) {
          this.showError("보증금을 입력해주세요");
          return;
        }
        if (!this.leaseTime) {
          this.showError("임대기간을 선택해주세요");
          return;
        }

        this.step = 5;
      } else if (this.step === 5) {
        if (this.placeInnerFiles.length === 0) {
          this.showError("공간 내부사진을 첨부해주세요");
          return;
        } else if (this.placeOuterFiles.length === 0) {
          this.showError("공간 외부사진을 첨부해주세요");
          return;
        } else if (this.placeOtherFiles.length === 0) {
          this.showError("공간 기타사진을 첨부해주세요");
          return;
        }

        this.loading = true;
        try {
          const success = await services.placeCreate({
            post: this.post,
            addr: this.address,
            addr2: this.oldAddress,
            addrDetail: this.addressDetail,
            latitude: this.latitude,
            longitude: this.longitude,
            classificationId: this.categoryId,
            size: this.size,
            utilities: this.utilities * 10000,
            minPrice: 0,
            flow: 1,
            roomCnt: 1,
            title: this.address,
            subTitle: this.address,
            explanation: "",
            leaseTime: this.leaseTime.id * 365,
            leasePrice: this.leasePrice * 10000,
            seller: this.address,
            isChangeable: this.isChangeable,
            "placeInnerFiles[]": this.placeInnerFiles,
            "placeOuterFiles[]": this.placeOuterFiles,
            "placeOtherFiles[]": this.placeOtherFiles,
          });

          if (success) {
            this.step = 6;
          } else {
            this.showError("문제가 발생했습니다");
          }
        } catch (e) {
          this.showError("문제가 발생했습니다");
        } finally {
          this.isLoading = false;
        }
      }
    },
    onHome() {
      this.$router.push({ name: "Home" });
    },
    onBack() {
      this.step = this.step - 1;
    },
    onAddressChange({
      postcode,
      address,
      addressDetail,
      oldAddress,
      latitude,
      longitude,
    }) {
      this.post = postcode;
      this.address = address;
      this.addressDetail = addressDetail;
      this.oldAddress = oldAddress;
      this.latitude = latitude;
      this.longitude = longitude;
    },
    onInnerFilesChange(value) {
      if (value.length > 0) {
        this.placeInnerFiles = [value[0]];
      } else {
        this.placeInnerFiles = [value];
      }
    },
    onOuterFilesChange(value) {
      if (value.length > 0) {
        this.placeOuterFiles = [value[0]];
      } else {
        this.placeOuterFiles = [value];
      }
    },
    onOtherFilesChange(value) {
      if (value.length > 0) {
        this.placeOtherFiles = [value[0]];
      } else {
        this.placeOtherFiles = [value];
      }
    },
    showError(message) {
      this.$toast.open({ message, type: "error" });
    },
  },
  computed: {
    ...mapState("place", ["classifications", "forms", "leases"]),
    ...mapState("user", ["isLoggedIn"]),
  },
};
</script>

<style scoped lang="scss">
.place-create-0 {
  margin-top: 120px;
  margin-bottom: 120px;

  h1 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -1.5px;
    text-align: center;
    color: #2e3ff0;
    margin-bottom: 30px;

    span {
      font-weight: normal;
    }
  }

  div {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: center;
    color: #fff;
    background-color: #283aef;
    padding: 2px 12px;
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 45px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.18px;
    text-align: center;
    color: #434343;
  }

  button {
    width: 215px;
    height: 60px;
    border-radius: 3px;
    background-color: #2e3ff0;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: -0.16px;
    text-align: center;
    color: #fff;
  }
}

.place-create-wrapper {
  width: 588px;
  border: 1px solid #d7d7d7;
  padding: 74px 65px 49px;
  margin-top: 78px;
  margin-bottom: 171px;

  h2 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: -1.2px;
    text-align: left;
    color: #333;
    margin-bottom: 35px;

    span {
      font-weight: 300;
    }
  }

  .description {
    margin-top: 38px;
    margin-bottom: 50px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #333;

    span {
      color: #283aef;
    }
  }

  .step-1,
  .step-2,
  .step-3,
  .step-4,
  .step-5,
  .step-6 {
    margin-bottom: 80px;
  }

  .step-7 {
    margin-top: 70px;

    p {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.32;
      letter-spacing: -0.56px;
      text-align: center;
      color: #333;
      margin-top: 32px;
      margin-bottom: 58px;
    }
  }

  .category,
  .time,
  .form,
  .style {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 10px;

    p {
      margin-left: -10px;
      margin-bottom: 10px;
    }

    .checkbox {
      margin-bottom: 8px;
    }
  }

  .hint {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: -0.48px;
    text-align: left;
    color: #555;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.56px;
    text-align: left;
    color: #555;

    span {
      color: #f20000;
    }

    &:not(:first-child) {
      margin-top: 26px;
    }
  }

  .button-group {
    button {
      height: 55px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.68;
      letter-spacing: normal;
      text-align: left;
      color: #fff;

      &.next {
        background-color: #283aef;
      }
      &.back {
        margin-top: 7px;
        background-color: #585858;
      }
    }
  }
}

@media (max-width: 639px) {
  .place-create-0 {
    h1 {
      font-size: 20px;
    }
    div {
      font-size: 13px;
    }
    p {
      font-size: 13px;
    }
    button {
      font-size: 13px;
      height: 40px;
    }
  }
  .place-create-wrapper {
    width: 100%;
    border: none;
    padding: 30px 20px;
  }
}
</style>
