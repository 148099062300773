<template>
  <div class="header-wrapper relative w-full flex items-center justify-center">
    <div class="header-inner flex flex-row items-center justify-end">
      <img class="header-image-1" src="@/assets/jumbotron-3.png" />
      <div class="header container flex flex-col relative">
        <div class="dot-group">
          <div class="dot" />
          <div class="dot" />
          <div class="dot" />
          <div class="dot" />
        </div>
        <h3>노는공간, 저렴하게 임대받자!</h3>
        <p>노는공간에 임대료를 내 마음대로,</p>
        <h1>
          내 생애 첫창업, 단돈 1만원이라도 아껴야죠!!
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Jumbotron3",
};
</script>

<style scoped lang="scss">
.header-wrapper {
  height: 600px;
  background-color: #283bf1;

  .header-inner {
    z-index: 2;
    padding-top: 40px;
    margin-bottom: -67px;
  }
}

.dot-group {
  position: absolute;
  top: -10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: white;
    margin-right: 24px;
  }
}

.header-image-1 {
  width: 150px;
  margin-right: 60px;
  margin-bottom: -30px;
}

.header {
  h3 {
    font-size: 40px;
    font-weight: 500;
    color: white;
    margin-bottom: 40px;
  }

  p {
    font-size: 24px;
    font-weight: 300 !important;
    color: white;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    color: white;
  }
}

.jumbotron-title-2 {
  margin-left: 10px !important;
}

@media (max-width: 767px) {
  .header-image-1 {
    display: none;
  }
  .dot-group {
    .dot {
      width: 8px;
      height: 8px;
      margin-right: 15px;
    }
  }
  .header {
    h3 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
    h1 {
      font-size: 18px;
    }
  }
  .header-wrapper {
    height: 400px;
    .header-inner {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 639px) {
  .dot-group {
    .dot {
      width: 5px;
      height: 5px;
      margin-right: 10px;
    }
  }
  .header {
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
    h1 {
      font-size: 14px;
    }
  }
  .header-wrapper {
    height: 300px;
  }
}
</style>
